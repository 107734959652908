import React from 'react'
import FB from "../../Media/facebook.svg"
import Instagram from "../../Media/instagram.svg"
import Youtube from "../../Media/youtube.svg"
import Twitter from "../../Media/twitter.svg"
import "../../App.scss"
function Socials() {
  return (
    <div className='d-f-row socialsBar'>
      <a className='d-f-col socialButton' href="https://www.instagram.com/kornel4465/?hl=en">
        <img src={Instagram} alt="Instagram" className='socialsIcon'/>
        <p>Instagram</p>
      </a>
      <a className='d-f-col socialButton' href="https://www.youtube.com/channel/UCV2CRPJdekNO_skigml-2Kw">
        <img src={Youtube} alt="Youtube" className='socialsIcon' />
        <p>Youtube</p>
      </a>
    </div>
  )
}

export default Socials