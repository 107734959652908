import React from 'react'
import "../App.scss"
function Footer() {
  return (
    <div className='creatorTag'>
        <p>Website Developed By DB Digtial Solutions</p>
    </div>
  )
}

export default Footer