import React, {useEffect,useState,useRef,forwardRef} from 'react'
import Hero from './Hero/Hero'
import NavBar from '../NavBar/NavBar'
import VideoSection from './Video/VideoSection.js'
import PortfolioWork from './My work/PortfolioWork.js'
import TimeFrame from './Time frame/TimeFrame'
import Prices from './Prices/Prices.js'
import ContactForm from './Contact me/ContactForm'
import Footer from '../Footer/Footer'
function MainContent() {
  const [activeSection, setActiveSection] = useState("");

  const heroRef = useRef(null);
  const videoRef = useRef(null);
  const portfolioRef = useRef(null);
  const timeFrameRef = useRef(null);
  const pricesRef = useRef(null);
  const contactRef = useRef(null);
  
  const sectionRefs = {
    hero: heroRef,
    video: videoRef,
    portfolioSection: portfolioRef,
    timeFrame: timeFrameRef,
    Prices: pricesRef,
    Contact: contactRef,
  };

  const offset = -40; // Adjust this value as needed

    // Calculate the desired scroll position
    

  const scrollToSection = (section) => {
    
    const targetElement = sectionRefs[section].current;
  const currentScrollPosition = window.scrollY; // Get the current scroll position
  const scrollPosition = targetElement.offsetTop + offset;

  setActiveSection(section);

  // Log the adjusted scroll position

  sectionRefs[section].current.scrollIntoView({
    top: scrollPosition,
    behavior: 'smooth',
  });
};
  return (
    <div className='mainContent'>
      <NavBar
      scrollToSection={scrollToSection}
      />
      <Hero id="hero" ref={heroRef}/>
      <VideoSection id="video" ref={videoRef}/>
      <PortfolioWork id="portfolioSection" ref={portfolioRef}/>
      <TimeFrame id="timeFrame" ref={timeFrameRef}/>
      <Prices id="Prices" ref={pricesRef}/>
      <ContactForm id="Contact" ref={contactRef}/>
      <Footer/>
    </div>
  )
}

export default MainContent