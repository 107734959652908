import React from 'react'

function PriceCard(props,title,price,time,description) {
  return (
    <div className='priceCard'>
        <h2 className='cardTitle'>{props.title}</h2>
        <h2 className='cardTime'>{props.time}</h2>
        <p className='cardDetails'>{props.description}</p>
        <h2 className='cardPrice'>{props.price}</h2>
    </div>
  )
}

export default PriceCard