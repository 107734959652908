import logo from './logo.svg';
import"./reset.css";
import './App.scss';
import Navbar from "../src/NavBar/NavBar.js"
import MainContent from "./Main Content/MainContent.js"

function App() {
  return (
    <div className='appWide'>
      <MainContent/>
    </div>
  );
}

export default App;
