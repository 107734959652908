import React, { forwardRef } from 'react'
import "../../App.scss"
import Image from "../../Media/personplaceholder.jpg"
import NavBar from '../../NavBar/NavBar'
const Hero = forwardRef((props,ref) => {
  return (
    <div className='heroSection' ref={ref}>
        <div className='introSection'>
            <div className='introOne'>
              {/* <img src={Image} alt="Kornell Nawrot self portrait" className='portraitImage'/> */}
              <section className='heroPassage'>
                <h1 className='heroHello'>Hello,</h1>
                <h1>I'm <span className='spanTextBoldL'>Kornel</span>.</h1>
              </section>
            </div>
            <div className='introPassage'>
              <p>I create bespoke, </p>
              <h2 className='spanTextBoldHero'>Photo-Realistic Art</h2>
              <p> for clients around the world.</p>
            </div>
        </div>
    </div>
  )
})

export default Hero