import React, {useState,useEffect} from 'react';
import "../App.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillCloseCircle } from "react-icons/ai";
import Socials from './Subcomponents/Socials';
function NavBar(props,setActiveSection,scrollToSection) {
  const [isOpen,setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(false);

  //handle the nav bar gaining opacity when scrolled.
  useEffect(()=> {
    const handleScroll = ()=> {
      if(window.scrollY > 50) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    }
    window.addEventListener("scroll", handleScroll);
    return() =>{
      window.removeEventListener("scroll",handleScroll);
    };
  },[])

  return (
    <div className={`d-f-row navBar ${scroll ? "scrolledBackground": null}`}>
      <div className='d-f-row'>
          <h3 className='navText'>Kornel <span className='spanText'>ART</span></h3>
        </div>
      <div className={`popOutMenu d-f-col ${isOpen ? "active" : ""}`}>
        <AiFillCloseCircle className='popIcon' onClick={()=> setIsOpen(false)}/>
        <ul className='d-f-col popButtons'>
          <li className='popButton' onClick={()=>{props.scrollToSection("hero");setIsOpen(false)}}>Home</li>
          <li className='popButton' onClick={()=>{props.scrollToSection("video");setIsOpen(false)}}>About</li>
          <li className='popButton' onClick={()=>{props.scrollToSection("portfolioSection");setIsOpen(false)}}>Artwork</li>
          <li className='popButton' onClick={()=>{props.scrollToSection("timeFrame");setIsOpen(false)}}>What to Expect</li>
          <li className='popButton' onClick={()=>{props.scrollToSection("Prices");setIsOpen(false)}}>Packages</li>
          <li className='popButton' onClick={()=>{props.scrollToSection("Contact");setIsOpen(false)}}>Contact</li>
        </ul>
      </div>
      <div className='d-f-row hamburger'>
        <GiHamburgerMenu className='navIcon' onClick={()=> setIsOpen(true)}/>
        
      </div>
    

      <ul className='d-f-row navButtons'>
        <li className='navButton textButton' onClick={()=>{props.scrollToSection("hero");setIsOpen(false)}}>Home</li>
        <li className='navButton textButton' onClick={()=>{props.scrollToSection("video");setIsOpen(false)}}>About</li>
        <li className='navButton textButton' onClick={()=>{props.scrollToSection("portfolioSection");setIsOpen(false)}}>Artwork</li>
        <li className='navButton textButton' onClick={()=>{props.scrollToSection("timeFrame");setIsOpen(false)}}>What To Expect</li>
        <li className='navButton textButton' onClick={()=>{props.scrollToSection("Prices");setIsOpen(false)}}>Packages</li>
        <li className='navButton textButton' onClick={()=>{props.scrollToSection("Contact");setIsOpen(false)}}>Contact</li>
      </ul>
    </div>
  )
}

export default NavBar