import React,{forwardRef} from 'react'
import "../../App.scss"
import Quinn from "../../Media/Raw media/quinn.png"
import Skywalker from"../../Media/Raw media/skywalker.jpg"
import Dog from"../../Media/Raw media/dog.png"
import Man from "../../Media/images/manPortrait.jpg"
import Punisher from"../../Media/images/punisher.jpg";
import Pikachu from"../../Media/images/pikachu.jpg";
const PortfolioWork = forwardRef((props,ref)=> {
  return (
    <div className='lightSection' ref={ref}>
        <section className='portfolioTextSection'>
          <div className='imageBlocks'>
            <div className='d-f-col imageBlock'>
              <img src={Quinn} alt="Black and white detailed pencil art of a man."/>
              <h2>PERSONALISED ART</h2>
              <p>Choosing an artist to create a personalised portrait your beloved ones can be doubting. This is why I ensure that I fully understand your needs to give your portraits that special touch to bring your vision to life through my art.</p>
            </div>
            <div className='d-f-col imageBlock'>
              <img src={Punisher} alt="Black and white cartoon style drawing of the punisher."/>
              <h2>TRUSTED SERVICE</h2>
              <p>I'm confident I can bring your ideas to life no matter how big or small your requests are. Your dream piece can be as intricate or vague as you desire.</p>
            </div>
            <div className='d-f-col imageBlock'>
            <img src={Skywalker} alt='Portrait drawing of harley quinn'/>
              <h2>HIGH-QUALITY ART</h2>
              <p>I work with high quality materials using Strathmore, Derwent or Faber-Castell paper for my portraits along with Faber-Castell & Derwent pencils and Faber-Castell Polychromos for my colour drawings.</p>
            </div>
          </div>
            <section>
              <h1>Services</h1>
              <img className='portfolioImage' src={Dog}></img>
              <div className='servicesBlock'>
                <h2>PET DRAWINGS</h2>
                <p> I mention this at the top because this is one of the most requested services. People just love having their fury friends captured in art. Throughout the years, I have gained experience making portraits of all types of pets. Whether you’d like a pet portrait in coloured pencil or black and white, I will deliver to a high-quality standard.</p>
              </div>
              <img className='portfolioImage' src={Man}></img>
              <div className='servicesBlock'>
                <h2>CHILD AND FAMILY PORTRAITS</h2>
                <p>My second most requested service is portraits of adults, children, and entire families. I adapt my drawing style for everyone, ensuring their natural look respecting the tone of their skin and the natural look in the pictures provided. Each of us have a special spark in our gaze. And I love to ensure that that spark is always translated in each of my pieces of art.</p>
              </div>
              <img className='portfolioImage' src={Pikachu}></img>
              <div className='servicesBlock'>
                <h2>MISCELLANEOUS ART</h2>
                <p>Every so often my clients request drawings of their favourite movie actors, game characters, or singers. And I absolutely love it! If you have any requests that are not listed above, please contact me and I will do my best to adapt to your needs.</p>
              </div>
            </section>
            
        </section>
    </div>
  )
})

export default PortfolioWork