import React,{forwardRef} from 'react'
import Video from "../Video/heroVideo.webm"
import "../../App.scss"

const VideoSection = forwardRef((props,ref)=> {
  
  return (
    <div className='videoSection d-f-col' ref={ref}>
      <video autoPlay muted loop className="heroVideo">
                <source src={Video} type="video/webm"/>
            </video>
        <section className='videoSectionContent'>
          <h1>Hello!</h1>
          <p>Welcome to Kornel Art, I am a drawing artist located in Manchester with over 20 years of experience drawing with pencils and colour pencils. I create a variety of pieces including bespoke portrait art, hand-drawn pet art and custom request drawings. </p>
          <p>Drawing is not just an art, but a passion, a passion I love sharing with my clients with every single art service delivered. </p>
          <p>Scroll further down this page to see some of my favourite peices of work that showcase my percision and versitility.</p>
        </section>
        <section>
        </section>
        
    </div>
  )
})

export default VideoSection