import React,{forwardRef} from 'react'
import PriceCard from './PriceCard'

const Prices = forwardRef((props,ref) => {
  return (
    <div className='priceSection' ref={ref}>
        <h1>PACKAGES</h1>
        <p className='priceSectionParagraph'>I have several packages for you to choose from. I can also do multiple peices of the same collection for a reduced price. Fill out the contact form at the bottom of this page and I will be in touch.</p>
        <section className='priceCards'>
            <PriceCard title={"A4 Black and Grey"} 
            price={"£200"} 
            time={"4 Weeks"} 
            description={"Exquisite black and white art hand-drawn using Faber-Castell and Derwent pencils on premium A4 size paper from renowned brands like Strathmore, Derwent, and Faber-Castell."}/>
            <div className='redUnderline'></div>
            <PriceCard title={"A4 Coloured "} 
            price={"£250"} 
            time={"4 weeks"} 
            description={"Unique colored art created with Faber-Castell and Derwent pencils on high-quality A3 size paper from Strathmore, Derwent, or Faber-Castell."}/>
            <div className='redUnderline'></div>
            <PriceCard title={"A3 Black and Grey Artwork"} 
            price={"£300"} 
            time={"4 weeks"} 
            description={"Exquisite black and white art hand-drawn using Faber-Castell and Derwent pencils on premium A3 size paper from renowned brands like Strathmore, Derwent, and Faber-Castell."}/>
            <div className='redUnderline'></div>
            <PriceCard title={"A3 Coloured Artwork"} 
            price={"£350"} 
            time={"4 weeks"} 
            description={"Unique colored art created with Faber-Castell and Derwent pencils on high-quality A3 size paper from Strathmore, Derwent, or Faber-Castell."}/>
        </section>
    </div>
  )
})

export default Prices