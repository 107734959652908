import React, { forwardRef } from 'react'
import "../../App.scss"
import Socials from '../../NavBar/Subcomponents/Socials';
const ContactForm = forwardRef((props,ref)=> {

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Get form field values
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const country = document.getElementById('country').value;
        const city = document.getElementById('city').value;
        const message = document.getElementById('message').value;
    
        // Format the email body without equal signs
        const emailBody = `
          name: ${name.replace(/^=/, '')}
          email: ${email.replace(/^=/, '')}
          country: ${country.replace(/^=/, '')}
          city: ${city.replace(/^=/, '')}
          message: ${message.replace(/^=/, '')}
        `;
    
        // Open the user's default email client with the formatted email body
        window.location.href = `mailto:kornelnaworot@gmail.com?body=${encodeURIComponent(emailBody)}`;
      };
    

  return (
    <div className='contactSection' ref={ref}>
        <section className='contactContent'>
            <h1>GET IN TOUCH</h1>
            <p>Have you got a piece of art in mind and would like to explore your options with me?</p>
            <p>Fill out the contact form in this section and I will be in touch with you to discuss your ideas further.</p>
            <form className='contactForm d-f-col' onSubmit={handleSubmit} >
                <div className='d-f-row topInput'>
                        <input type="text" id="name" name="Name: " placeholder='Full Name'required/>
                        <input type="email" id="email" name="Email: " placeholder='Email Address'required/>
                </div>
                <div className='d-f-row secondInput'>
                        <input type="text" id="country" name="Country: " placeholder='Country'required/>
                        <input type="text" id="city" name="City: " placeholder='City'required/>
                </div>
                <div className='d-f-col messageArea'>
                    <textarea id="message" name="Message: " placeholder='Please use this area to provide me with some information on your request.'required></textarea>
                </div>
                <input className="contactButton"type="submit" value="Enquire Now"/>
            </form>
            <div className='redBreakline'></div>
            <h2>EXPLORE MORE OF MY WORK...</h2>
            <p>Email: kornelnaworot@gmail.com</p>
            <Socials/>
        </section>
    </div>
  )
})

export default ContactForm