import React,{forwardRef} from 'react'
import "../../App.scss"
const TimeFrame= forwardRef((props, ref)=> {
  return (
    <div className='timeFrameSection' ref={ref}>
        <h1 >WHAT TO EXPECT</h1>
        <div className='timeLine'>
            <section className='eventSection d-f-row'>
                <div className='overLine'></div>
                <section className='d-f-col eventText'>
                    <h2>Initial Consultation</h2>
                    <p>I will consult with you to understand your needs. All my artwork is based on photography, so you do not have to travel anywhere for your art to be completed! This allows me to work with clients from all over the world and ensures a quicker delivery than other portrait services.</p>
                </section>
            </section>
            <section className='eventSection d-f-row'>
                <div className='overLine'></div>
                <section className='d-f-col eventText'>
                    <h2>Begin Work</h2>
                    <p>Once I start working on our project, I will keep you posted throughout the process, sending updates every few days to ensure we are on the right path. I will usually charge 50% at the start of every work and the remaining 50% upon finalisation.</p>
                </section>
            </section>
            <section className='eventSection d-f-row'>
                <div className='overLine'></div>
                <section className='d-f-col eventText'>
                    <h2>Review</h2>
                    <p>Sometimes clients request small changes in their portraits before delivery. This is why I will always send you a photograph of the finalised work to ensure that you are happy with my services.</p>
                </section>
            </section>
            <section className='eventSection d-f-row'>
                <div className='overLine'></div>
                <section className='d-f-col eventText'>
                    <h2>Final Adjustments</h2>
                    <p> I will frame my artwork in the form that you request for our initial consultation. If you would like a frame sent to you via post along with your artwork, this can be done but it will infringe additional costs.</p>
                </section>
            </section>
            <section className='eventSection d-f-row'>
                <div className='overLine'></div>
                <section className='d-f-col eventText'>
                    <h2>Delivery Of Work</h2>
                    <p>Once the artwork is completed and ready for shipment, I will send it to your address via a secure method of postage. </p>
                </section>
            </section>
        </div>  
    </div>
  )
})

export default TimeFrame